<template>
  <el-row class="gap-y-2">
    <!-- <el-col class="bg-white p-4 rounded">
      <h3 class=" text-lg text-blue-900 font-normal">
        Orientações básicas para utilização do sistema
        <el-link
          href="Presentation.pdf"
          target="_blank"
          type="primary"
        >{{ '>>> download PDF <<<' }}</el-link>
      </h3>
    </el-col> -->
    <el-col>
      <el-card shadow="none" class="box-card">
        <template #header>
          <div class="text-xl font-bold">
            <span>Atualizações do sistema:</span>
          </div>
        </template>
        <div v-for="version in versionsReverse" :key="version.id" class="item">
          <h3 class="text">
            {{ `Versão: ${version.id}` }}
            <span class="text-sm font-normal text-gray-500">{{ `(${version.date})` }}</span>
          </h3>
          <p
            v-for="update, index in version.updates"
            :key="update"
            class="ml-4 text-md text-muted"
          >
            {{ `${update}${index === (version.updates.length - 1) ? '.' : ';'}` }}
          </p>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'Versions',
  data() {
    return {
      versions: [
        {  
          id: '1.0.0',
          date: '31/01/2023',
          updates: [
            'Lista de usuários',
            'Filtro por unidade',
            'Ativar/desativar usuário',
            'Conceder/retirar permissões (Comandante, SsJD, Encarregado)',
            'Criar usuário', 
          ]
        },
        {
          id: '1.1.1',
          date: '03/02/2023',
          updates: [
            'Envio de e-mail de forma automática',
            'Ajuste no componente de paginação', 
          ]
        },
        {
          id: '1.2.1',
          date: '24/02/2023',
          updates: [
            'Criação da tela inicial (Gráficos e informações)',
            'Criação do filtro por nome/matrícula/CPF',
            'Notificação de quando o usuário está desatualizado (menu Editar)' 
          ]
        },
        {
          id: '1.3.1',
          date: '06/03/2023',
          updates: [
            'Edição de dados pessoais (E-mail e telefone)'
          ]
        },
        {
          id: '1.4.1',
          date: '15/03/2023',
          updates: [
            'Atualização automática de usuários (unidade, quadro, posto/graduação, divisão, seção)',
            'Ajuste na label da unidade na lista de usuários',
          ]
        },
        {
          id: '1.5.1',
          date: '24/03/2023',
          updates: [
            'Criação do novo layout',
            'Criação do gráfico com mapa de calor dos usuários SsJD',
            'Melhoria de performance'
          ]
        },
        {
          id: '1.6.1',
          date: '13/06/2023',
          updates: [
            'Atualização da arquitetura das APIs'
          ]
        },
        {
          id: '1.7.1',
          date: '09/08/2023',
          updates: [
            'Atualização do layout da plataforma',
            'Ajustes na responsividade dos componentes',
            'Criação e edição dos usuários do grupo DCC',
            'Gestão de permissões dos usuários do grupo DCC',
            'Atualização/criação de endpoints da API integrada com o AD'
          ]
        },
        {
          id: '1.8.1',
          date: '09/11/2023',
          updates: [
            'Melhoria de performance',
            'Atualização das regras de segurança no cadastramento de senhas na API e no Site'
          ]
        },
        {
          id: '1.8.2',
          date: '26/12/2023',
          updates: [
            'Correção do fluxo da criação de usuário',
          ]
        },
        {
          id: '1.9',
          date: '08/04/2024',
          updates: [
            'Criação da estrutura dos níveis de acesso da plataforma',
            'Melhorias de performance e regras de segurança'
          ]
        }
      ]
    }
  },
  computed: {
    versionsReverse() {
      return this.versions.reverse()
    }
  }
}
</script>

<style scoped>

.text {
  font-size: 18px;
  font-style: italic;
  font-weight: bold;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 100%;
}
</style>